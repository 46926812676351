@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700);
* {
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

html, body {
    margin: 0;
    padding: 0;
}

body {
    background-color: #e7ebf0;
}

.container {
    width: 100%;
    margin: 20px auto;
    max-width: 1500px;
}

.center {
    display: flex;
    justify-content: center;
}

.hide {
    display: none;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */



#wrapper {
  /* display: flex; */
  width: 220px;
  position: relative;
}

#signature {
	max-width: 350px;
  }
  path {
	fill: none;
	stroke: #000;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-miterlimit: 10;
  }
  .line1 {
	stroke-dasharray: 130.75833129882812;
  -webkit-animation: write1 2s linear;
			animation: write1 2s linear;
  }
  .line2 {
	stroke-dasharray: 264.064697265625;
  -webkit-animation: write2 3s linear;
			animation: write2 3s linear;
  }
  .line3 {
	stroke-dasharray: 293.2086181640625;
  -webkit-animation: write3 4s linear;
			animation: write3 4s linear;
  }
  .line4 {
	stroke-dasharray: 1056.37548828125;
  -webkit-animation: write4 5s linear;
			animation: write4 5s linear;
  }
  
  @-webkit-keyframes write1 {
	0% {
	  stroke-dashoffset: 130.75833129882812;
	}
	15% {
	  stroke-dashoffset: 0;
	}
  }
  @keyframes write1 {
	0% {
	  stroke-dashoffset: 130.75833129882812;
	}
	15% {
	  stroke-dashoffset: 0;
	}
  }
  @-webkit-keyframes write2 {
	0%, 20% {
	  stroke-dashoffset: 264.064697265625;
	}
	35% {
	  stroke-dashoffset: 0;
	}
  }
  @keyframes write2 {
	0%, 20% {
	  stroke-dashoffset: 264.064697265625;
	}
	35% {
	  stroke-dashoffset: 0;
	}
  }
  @-webkit-keyframes write3 {
	0%, 38% {
	  stroke-dashoffset: 293.2086181640625;
	}
	60% {
	  stroke-dashoffset: 0;
	}
  }
  @keyframes write3 {
	0%, 38% {
	  stroke-dashoffset: 293.2086181640625;
	}
	60% {
	  stroke-dashoffset: 0;
	}
  }
  
  @-webkit-keyframes write4 {
	0%, 61% {
	  stroke-dashoffset: 1056.37548828125;
	}
	100% {
	  stroke-dashoffset: 0;
	}
  }
  
  @keyframes write4 {
	0%, 61% {
	  stroke-dashoffset: 1056.37548828125;
	}
	100% {
	  stroke-dashoffset: 0;
	}
  }


.topnav {
  overflow: hidden;
  background-color: #333;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #4CAF50;
  color: white;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

.menu {
  background-color: white;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 80%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 0px 0px;
  position: relative;
  min-width: 390px;
}

.menu__right {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}

.menu__list {
  list-style-type: none;
  padding: 0;
  height: 100%;
  margin: 0;
  margin-right: 30px;
}

.menu__list-item {
  display: inline-block;
  height: 100%;
  margin-right: 20px;
}

.menu__list-item:last-child {
  margin-right: 0;
}

.menu__link {
  color: #3a3e47;
  display: inline-block;
  height: 100%;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 3px;
}

.menu__link:hover {
  color: #09d3ac;
}

.menu__link--active {
  color: #09d3ac;
  border-top: 4px solid #09d3ac;
  font-weight: 700;
}

.menu__logo {
  text-indent: -9999999px;
  display: inline-block;
  height: 100%;
  width: 92px;
  background-size: cover 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
}

.menu__search-button {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 1px solid #c8c9ce;
  background-color: transparent;
  background-image: url("/shoppingcart.png");
  outline: none;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}

.menu__search-form {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 10px;
  background-color: white;
}

.menu__search-input {
  outline: none;
  border: 1px solid #c8c9ce;
  font-size: 18px;
  padding: 5px;
  font-weight: 300;
}

#root{
  width: 100%;
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  background-color: white;
  margin-right: 11px;
}
.dropul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top:145%;
  right:0px;
  background-color: white;
  font-weight:bold;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.fa{
  font-size: 1.2em !important;
  position: absolute;
  top: 26%;
  left: 31%;
}

.fa:active{
  color: #09d3ac;
}

.dropa {
  color: #000;
  text-decoration: none;
  font-weight: 550;
}

.dropa:hover {
  color: #09d3ac;
  text-decoration: none;
}

.dropli {
  padding: 8px 16px;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
}
.dropli:last-child {
  border-bottom: none;
}
/* .dropli:hover {
  background-color: #e5e5e5;
  color: #e5e5e5;
} */
.buttondrop{
  cursor: pointer;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 1px solid #c8c9ce;
  background-color: transparent;
  font-weight:bold;
  color:black;
}


.productcontainer {
  justify-content: center; }

@media only screen and (max-width: 750px) {
  h3.productdiv {
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    font-size: 3em !important;
    font-weight: 300;
    background: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 300px;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15); }
  h3.ProductTitle {
    color: #09d3ac; }
  .producta {
    margin-top: -19px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    background: white;
    font-weight: 350;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    color: #09d3ac;
    width: 300px;
    box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.03), -10px 12px 15px 0px rgba(0, 0, 0, 0.03), 10px 12px 15px 0px rgba(0, 0, 0, 0.03) !important; } }

h3.productdiv {
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  font-size: 2em !important;
  font-weight: 300;
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  max-width: 500px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15); }

h3.ProductTitle {
  color: #09d3ac; }

.producta {
  margin-top: -19px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  padding-bottom: 10px;
  background: white;
  font-weight: 350;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #09d3ac;
  max-width: 500px;
  box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.03), -10px 12px 15px 0px rgba(0, 0, 0, 0.03), 10px 12px 15px 0px rgba(0, 0, 0, 0.03) !important; }

.box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.material-icons {
  font-size: 2.4em;
  cursor: pointer; }

.card {
  width: 300px;
  margin-right: 10px !important; }

.card .card-image .card-title {
  color: black !important;
  font-weight: 400; }

.cart {
  margin-top: 40px; }

.collection .collection-item {
  display: flex; }

.collection .collection-item.avatar {
  padding: 15px !important; }

.collection-item.avatar .title {
  font-size: 20px;
  font-weight: 500;
  color: #46b691;
  display: block;
  margin-bottom: 20px; }

.collection .collection-item img {
  width: 150px;
  margin-right: 25px; }

.item-desc {
  align-self: center; }

*, *:before, *:after {
  box-sizing: border-box; }

.lighter-text {
  color: #ABB0BE; }

.main-color-text {
  color: #09d3ab00; }

nav {
  padding: 20px 0 40px 0;
  background: #F8F8F8;
  font-size: 16px; }
  nav .navbar-left {
    float: left; }
  nav .navbar-right {
    float: right; }
  nav ul li {
    display: inline; }
    nav ul li a {
      color: #777777;
      text-decoration: none; }
      nav ul li a:hover {
        color: black; }

nav ul a:hover {
  background-color: white !important; }

.MuiBadge-anchorOriginTopRightRectangle {
  top: -19px !important;
  left: -5px !important; }

.container2 {
  width: 100% !important;
  max-width: 1500px !important;
  margin: 20px auto !important; }

.add-remove {
  display: flex;
  justify-content: center;
  padding: 10px; }
  .add-remove button {
    height: 40px;
    position: unset;
    box-shadow: inset 0px 39px 0px -24px #a4dbd634;
    background-color: #a4dbd634;
    border-radius: 4px;
    border: 1px solid #ffffff;
    display: inline-block;
    cursor: pointer;
    text-shadow: 0px 1px 0px #1a746b; }
    .add-remove button i {
      justify-content: center;
      position: relative;
      height: 50%;
      line-height: 80%; }
  .add-remove button:hover {
    background-color: #a0a0a098; }
  .add-remove button:active {
    position: relative;
    top: 1px; }
  .add-remove button:focus {
    background-color: #a0a0a098; }

.shopping-cart {
  position: absolute;
  top: 100%;
  max-width: 380px;
  right: 0;
  padding: 10px;
  background-color: white;
  z-index: 2;
  color: black;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px; }
  .shopping-cart .shopping-cart-header {
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 15px; }
    .shopping-cart .shopping-cart-header .shopping-cart-total {
      float: right; }
  .shopping-cart .shopping-cart-items {
    padding-top: 20px;
    margin-right: 0%; }
    .shopping-cart .shopping-cart-items li {
      margin-bottom: 18px; }
    .shopping-cart .shopping-cart-items img {
      float: left;
      margin-right: 12px; }
    .shopping-cart .shopping-cart-items .item-name {
      display: block;
      padding-top: 10px;
      font-size: 16px; }
    .shopping-cart .shopping-cart-items .item-price {
      color: #09d3ab00;
      margin-right: 8px; }
    .shopping-cart .shopping-cart-items .item-quantity {
      color: #ABB0BE; }

.shopping-cart:after {
  bottom: 100%;
  left: 89%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: white;
  border-width: 8px;
  margin-left: -35px; }

.cart-icon {
  color: #515783;
  font-size: 24px;
  margin-right: 7px;
  float: left; }

.button {
  background: #09d3ab00;
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  display: block;
  border-radius: 3px;
  font-size: 16px;
  margin: 25px 0 15px 0; }
  .button:hover {
    background: rgba(10, 226, 183, 0); }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.btn-floating i {
  background-color: #09d3ac; }

#form-response {
  position: absolute;
  margin: auto;
  bottom: 42%;
  left: 48%;
  font-size: 4vh;
  color: #09d3ac;
  margin: -.3em; }

@media only screen and (max-width: 750px) {
  .wrapper {
    display: flex;
    flex-direction: column; }
  #form-response {
    position: absolute;
    margin: auto;
    bottom: -4%;
    left: 45%;
    font-size: 4vh;
    color: #09d3ac; } }

.wrapper {
  display: flex; }

.columncontact {
  display: flex;
  flex-direction: column; }

.columncontact > div {
  font-size: 1em;
  color: #09d3ac;
  background: white;
  margin: .4em;
  padding: .3em 1em;
  border-radius: 8px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
  flex: 1 1; }

label {
  color: #09d3ac;
  font-size: 24px !important; }

* {
  box-sizing: border-box; }

textarea {
  width: 300px;
  height: 150px; }

input[type=text], input[type=email], select, textarea {
  width: 100%;
  padding: 12px;
  font-size: 1em !important;
  color: black !important;
  border: 1px solid #ccc !important;
  border-radius: 8px  !important;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical; }

::-webkit-input-placeholder {
  /* Edge */
  color: #ccc !important;
  padding-left: 5px !important;
  font-size: .5em !important; }

:-ms-input-placeholder {
  /* Internet Explorer */
  color: #ccc !important;
  padding-left: 5px !important;
  font-size: .5em !important; }

::-moz-placeholder {
  color: #ccc !important;
  padding-left: 5px !important;
  font-size: 1em !important; }

::-ms-input-placeholder {
  color: #ccc !important;
  padding-left: 5px !important;
  font-size: 1em !important; }

::placeholder {
  color: #ccc !important;
  padding-left: 5px !important;
  font-size: 1em !important; }

input[type=submit] {
  background-color: #09d3ac;
  color: white;
  padding: 12px 20px;
  border: none;
  font-size: 1em !important;
  border-radius: 8px;
  cursor: pointer; }

input[type=submit]:hover {
  background-color: #067c65; }

.container {
  border-radius: 8px;
  padding: 20px; }

.portlist{
    display: flex;
    flex-direction: column;
    margin: 5px;
    justify-content: center;
    align-items: center;
}

.flip-card {
    background-color: transparent;
    width: 700px;
    height: 400px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    padding: 5px;
    margin: 5px;
  }
  /* 700 400 */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-transition: -webkit-transform 0.6s;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    border-radius: 8px;
    box-shadow: 0 0 7px 2px rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .Expercompany{
    font-size: 2em;
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  .flip-card-back {
    background-color: #2980b9;
    color: white;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  /* ------------------------ */
  .mobileflip-card {
    background-color: transparent;
    width: 350px;
    height: 647px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    padding: 5px;
    margin: 5px;
  }
  /* 700 400 */
  .mobileflip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-transition: -webkit-transform 0.6s;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .mobileflip-card:hover .mobileflip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .mobileExpercompany{
    font-size: 2em;
  }
  
  .mobileflip-card-front, .mobileflip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  
  .mobileflip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  .mobileflip-card-back {
    background-color: #2980b9;
    color: white;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
.section-wrapper {
  width: 100%;
  float: left;
  text-align: left;
  color: #9099a0;
  font-weight: 300;
  margin-bottom: 20px;
  padding: 0em 1em 0em 1em; }
  @media (max-width: 450px) {
    .section-wrapper {
      width: 100%; } }
  .section-wrapper:nth-child(3) {
    padding-right: 8%; }
  .section-wrapper h3.section-title {
    color: #09d3ac;
    text-align: left;
    text-transform: uppercase;
    font-size: 1.2em;
    margin-bottom: 20px;
    font-weight: 400; }
  .section-wrapper .skill-percentage {
    margin-bottom: 10px;
    position: relative; }
    .section-wrapper .skill-percentage::after {
      content: "";
      width: 100%;
      height: 6px;
      background: #4a4e51;
      display: block;
      margin-top: 3px; }
    .section-wrapper .skill-percentage::before {
      content: "";
      height: 6px;
      background: #09d3ac;
      position: absolute;
      margin-top: 3px;
      bottom: 0; }
    .section-wrapper .skill-percentage:nth-child(1)::before {
      width: 98%;
      -webkit-animation: skill_1 1s ease;
              animation: skill_1 1s ease; }
    .section-wrapper .skill-percentage:nth-child(2)::before {
      width: 98%;
      -webkit-animation: skill_2 1s ease;
              animation: skill_2 1s ease; }
    .section-wrapper .skill-percentage:nth-child(3)::before {
      width: 90%;
      -webkit-animation: skill_3 1s ease;
              animation: skill_3 1s ease; }
    .section-wrapper .skill-percentage:nth-child(4)::before {
      width: 95%;
      -webkit-animation: skill_4 1s ease;
              animation: skill_4 1s ease; }
    .section-wrapper .skill-percentage:nth-child(5)::before {
      width: 70%;
      -webkit-animation: skill_5 1s ease;
              animation: skill_5 1s ease; }
    .section-wrapper .skill-percentage:nth-child(6)::before {
      width: 74%;
      -webkit-animation: skill_6 1s ease;
              animation: skill_6 1s ease; }
    .section-wrapper .skill-percentage:nth-child(7)::before {
      width: 90%;
      -webkit-animation: skill_7 1s ease;
              animation: skill_7 1s ease; }
    .section-wrapper .skill-percentage:nth-child(8)::before {
      width: 70%;
      -webkit-animation: skill_8 1s ease;
              animation: skill_8 1s ease; }
    .section-wrapper .skill-percentage:nth-child(9)::before {
      width: 80%;
      -webkit-animation: skill_9 1s ease;
              animation: skill_9 1s ease; }
    .section-wrapper .skill-percentage:nth-child(10)::before {
      width: 90%;
      -webkit-animation: skill_10 1s ease;
              animation: skill_10 1s ease; }
    .section-wrapper .skill-percentage:nth-child(11)::before {
      width: 88%;
      -webkit-animation: skill_11 1s ease;
              animation: skill_11 1s ease; }
    .section-wrapper .skill-percentage:nth-child(12)::before {
      width: 80%;
      -webkit-animation: skill_12 1s ease;
              animation: skill_12 1s ease; }
    .section-wrapper .skill-percentage:nth-child(13)::before {
      width: 92%;
      -webkit-animation: skill_13 1s ease;
              animation: skill_13 1s ease; }
    .section-wrapper .skill-percentage:nth-child(14)::before {
      width: 74%;
      -webkit-animation: skill_14 1s ease;
              animation: skill_14 1s ease; }
    .section-wrapper .skill-percentage:nth-child(15)::before {
      width: 96%;
      -webkit-animation: skill_15 1s ease;
              animation: skill_15 1s ease; }
    .section-wrapper .skill-percentage:nth-child(16)::before {
      width: 70%;
      -webkit-animation: skill_16 1s ease;
              animation: skill_16 1s ease; }

@-webkit-keyframes skill_1 {
  0% {
    width: 0%; }
  100% {
    width: 98%; } }

@keyframes skill_1 {
  0% {
    width: 0%; }
  100% {
    width: 98%; } }

@-webkit-keyframes skill_2 {
  0% {
    width: 0%; }
  100% {
    width: 98%; } }

@keyframes skill_2 {
  0% {
    width: 0%; }
  100% {
    width: 98%; } }

@-webkit-keyframes skill_3 {
  0% {
    width: 0%; }
  100% {
    width: 90%; } }

@keyframes skill_3 {
  0% {
    width: 0%; }
  100% {
    width: 90%; } }

@-webkit-keyframes skill_4 {
  0% {
    width: 0%; }
  100% {
    width: 95%; } }

@keyframes skill_4 {
  0% {
    width: 0%; }
  100% {
    width: 95%; } }

@-webkit-keyframes skill_5 {
  0% {
    width: 0%; }
  100% {
    width: 70%; } }

@keyframes skill_5 {
  0% {
    width: 0%; }
  100% {
    width: 70%; } }

@-webkit-keyframes skill_6 {
  0% {
    width: 0%; }
  100% {
    width: 74%; } }

@keyframes skill_6 {
  0% {
    width: 0%; }
  100% {
    width: 74%; } }

@-webkit-keyframes skill_7 {
  0% {
    width: 0%; }
  100% {
    width: 90%; } }

@keyframes skill_7 {
  0% {
    width: 0%; }
  100% {
    width: 90%; } }

@-webkit-keyframes skill_8 {
  0% {
    width: 0%; }
  100% {
    width: 70%; } }

@keyframes skill_8 {
  0% {
    width: 0%; }
  100% {
    width: 70%; } }

@-webkit-keyframes skill_9 {
  0% {
    width: 0%; }
  100% {
    width: 80%; } }

@keyframes skill_9 {
  0% {
    width: 0%; }
  100% {
    width: 80%; } }

@-webkit-keyframes skill_10 {
  0% {
    width: 0%; }
  100% {
    width: 90%; } }

@keyframes skill_10 {
  0% {
    width: 0%; }
  100% {
    width: 90%; } }

@-webkit-keyframes skill_11 {
  0% {
    width: 0%; }
  100% {
    width: 88%; } }

@keyframes skill_11 {
  0% {
    width: 0%; }
  100% {
    width: 88%; } }

@-webkit-keyframes skill_12 {
  0% {
    width: 0%; }
  100% {
    width: 80%; } }

@keyframes skill_12 {
  0% {
    width: 0%; }
  100% {
    width: 80%; } }

@-webkit-keyframes skill_13 {
  0% {
    width: 0%; }
  100% {
    width: 92%; } }

@keyframes skill_13 {
  0% {
    width: 0%; }
  100% {
    width: 92%; } }

@-webkit-keyframes skill_14 {
  0% {
    width: 0%; }
  100% {
    width: 74%; } }

@keyframes skill_14 {
  0% {
    width: 0%; }
  100% {
    width: 74%; } }

@-webkit-keyframes skill_15 {
  0% {
    width: 0%; }
  100% {
    width: 96%; } }

@keyframes skill_15 {
  0% {
    width: 0%; }
  100% {
    width: 96%; } }

@-webkit-keyframes skill_16 {
  0% {
    width: 0%; }
  100% {
    width: 70%; } }

@keyframes skill_16 {
  0% {
    width: 0%; }
  100% {
    width: 70%; } }

*, *::after, *::before {
  box-sizing: border-box; }

.company-wrapper {
  width: 30%;
  float: left;
  text-align: left;
  padding-right: 5%;
  margin-bottom: 60px; }

.job-wrapper {
  width: 70%;
  float: left;
  text-align: left;
  padding-right: 5%;
  margin-bottom: 60px; }

.experience-title {
  color: #09d3ac;
  margin-bottom: 15px;
  font-size: 1.2em;
  text-align: left; }

h3 {
  font-weight: 650; }

@media only screen and (max-width: 950px) {
  .wrapper1 {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    justify-content: center;
    width: 90%; }
  .experience-title {
    color: #09d3ac;
    margin-bottom: 15px;
    font-size: 1em;
    text-align: left; }
  h3 {
    font-size: 1.2em !important; } }

.wrapper1 {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 90%; }

.column {
  display: flex;
  flex-direction: column; }

.column > div {
  font-size: 1.2em;
  background: white;
  margin: .2em;
  border-radius: 8px;
  flex: 1 1;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15); }

.section-padding {
  padding: 0px 0px 0px 5%; }

.experience {
  font-size: 1em; }

.spacing {
  margin-bottom: 20px;
  position: relative;
  text-align: center; }

.button1 {
  width: 30%;
  right: 30%;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
  color: #09d3ac;
  margin: .5em;
  font-size: 3vh;
  font-weight: 300;
  cursor: pointer;
  border: none;
  padding: 10px;
  text-align: center; }

.button1:hover {
  border: 2px solid #09d3ac; }

img {
  border-radius: 8px; }


.portlist{
    display: flex;
    flex-direction: column;
    margin: 5px;
    justify-content: center;
    align-items: center;
}

.flip-card {
    background-color: transparent;
    width: 700px;
    height: 400px;
    perspective: 1000px;
    padding: 5px;
    margin: 5px;
  }
  /* 700 400 */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 8px;
    box-shadow: 0 0 7px 2px rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .Expercompany{
    font-size: 2em;
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  .flip-card-back {
    background-color: #2980b9;
    color: white;
    padding-right: 10px;
    padding-left: 10px;
    transform: rotateY(180deg);
  }

  /* ------------------------ */
  .mobileflip-card {
    background-color: transparent;
    width: 350px;
    height: 647px;
    perspective: 1000px;
    padding: 5px;
    margin: 5px;
  }
  /* 700 400 */
  .mobileflip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .mobileflip-card:hover .mobileflip-card-inner {
    transform: rotateY(180deg);
  }

  .mobileExpercompany{
    font-size: 2em;
  }
  
  .mobileflip-card-front, .mobileflip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    backface-visibility: hidden;
  }
  
  .mobileflip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  .mobileflip-card-back {
    background-color: #2980b9;
    color: white;
    padding-right: 10px;
    padding-left: 10px;
    transform: rotateY(180deg);
  }